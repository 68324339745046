import Rails from '@rails/ujs'
import * as Turbo from "@hotwired/turbo"
import {Application} from "@hotwired/stimulus"
import {definitionsFromContext} from "@hotwired/stimulus-webpack-helpers"

// Init Rails helpers
Rails.start()

// Init Stimulus
window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// Init Rails helpers
document.addEventListener("turbo:load", function () {
    Rails.refreshCSRFTokens()
})