import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static get targets() {
        return ['navigation']
    }

    toggle() {
        this.navigationTarget.classList.toggle('collapsed')
        document.body.classList.toggle('noscroll')
        document.documentElement.classList.toggle('noscroll')
    }

    closeMobile() {
        this.navigationTarget.classList.add('collapsed')
        document.body.classList.remove('noscroll')
        document.documentElement.classList.remove('noscroll')
    }
}