import Flickity from "flickity"
import 'flickity-fade'
import 'flickity-imagesloaded'
import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static get targets() {
        return ['carousel']
    }

    connect() {
        let gallery = new Flickity(this.carouselTarget, {
            prevNextButtons: false,
            pageDots: '.home-header-carousel-dots',
            cellAlign: 'left',
            autoPlay: true,
            fade: true,
            imagesLoaded: true,
        })

        gallery.resize();
    }
}