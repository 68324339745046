import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return ["input"]
    }

    static values = {
        sitekey: String,
        name: String
    }

    connect() {
        this.form = this.element.closest("form");

        this.form.onsubmit = (e) => {
            // Check if this is our custom submitEvent (to avoid an infinite loop)
            if (!(e.detail?.by === 'Frontier')) {
                e.preventDefault();

                grecaptcha.ready(() => {
                    grecaptcha.execute(this.sitekeyValue, {action: this.nameValue}).then((token) => {
                        this.changeRecaptcha(token);
                        this.fireSubmitEvent();
                    });
                });
            }
        }
    }

    getSitekey() {
        return this.inputTarget.dataset.sitekey;
    }

    getName() {
        return this.inputTarget.dataset.name;
    }

    changeRecaptcha(token) {
        this.inputTarget.value = token;
    }

    fireSubmitEvent() {
        // Own custom submitEvent is fired to re-enable turbo (requestSubmit does not work)
        // A payload (detail) is given to the custom submitEvent to prevent an infinite loop
        this.form.dispatchEvent(new CustomEvent('submit', {
            bubbles: true,
            detail: {
                by: 'Frontier'
            }
        }))
    }
}